import axios from 'axios';
import Cookies from 'js-cookie'

const HOST = 'https://justus-dev.builtwithdark.com'

export default async function api() {}

api.auth = async function(username, password) {
  return axios.post(HOST+'/token/', {
  	username: username,
  	password: password
  }).then(res => {
  	return res.data
  })
}

api.email = async function(email) {
	return axios.post(HOST+'/email', {
		email: email.toLowerCase()
	}).then(res => {
		return res.data
	}).catch(err => {
    console.log(err)
  })
}

api.validatePassword = async function(email, password) {
  return axios.post(HOST+'/validatePassword', {
    email: email.toLowerCase(),
    password: password
  }).then(res => {
    console.log('here')
    return res.data
  }).catch(err => {
    console.log(err)
  })
}

api.setPassword = async function(email, password) {
  return axios.post(HOST+'/setPassword', {
    email: email.toLowerCase(),
    password: password
  }).then(res => {
    console.log('here')
    return res.data
  }).catch(err => {
    console.log(err)
  })
}
